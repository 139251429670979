import * as React from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import Cake from "../components/cake";
import BrandHeading from "../components/brand-heading";
import { Link } from "gatsby"
import { useProducts } from "../hooks/use-products";
import { usePrices } from "../hooks/use-prices";
import { DietaryRequirementsSection } from "../components/dietary-options";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Cakes() {
  const prices = usePrices();
  const products = useProducts().sort((x, y) => x.metadata.sort - y.metadata.sort);

  const kids = products.filter(x => x.metadata.subType == "kids")[0];
  const petite = products.filter(x => x.metadata.subType == "petite")[0];
  const celebration = products.filter(x => x.metadata.subType == "celebration")[0];

  const cakes = [petite, celebration].map(cake => {
    const cakePrices = prices.filter(x => x.product.id === cake.id);
    cake = {
      ...cake
      , code: cake.metadata.code
      , imageFile: cake.localImage[0]
      , options: cakePrices.map(x => { return { id: x.id, size: x.description, price: (x.unit_amount) / 100 }; })
    }
    cake.name = `${cake.metadata.subType} cakes`
    return <Link key={cake.code} to={`/cakes/${cake.metadata.subType}`}>
      <CakeCategory cake={cake} />
    </Link>
  })



  return (
    <>
      <Seo title="Shop" />
      <BrandHeading title="shop" />
      <section>
        <DietaryRequirementsSection className="mb-10 text-center text-sm md:text-base" />
        <div className="flex flex-wrap justify-evenly">
          {cakes}
        </div>
      </section>
    </>
  )
}

function CakeCategory({ cake }) {
  const sortedPrice = cake.options.map(x => x.price).sort((a, b) => a - b)
  const lowestPrice = (sortedPrice) ? sortedPrice[0] : 0
  const image = getImage(cake.imageFile)
  return (
    <div className="flex flex-col items-center md:px-4 mb-12 sm:mb-16">
      <GatsbyImage image={image} alt="Pasteleria Cake" className="h-70 w-70 sm:h-80 sm:w-80" />
      <div className="text-center md:text-left max-w-2xs px-2 py-6 flex flex-col">
        <div className="text-center ml-3">
          <h3 className="font-ovo lowercase text-lg tracking-wider text-gray-800">{cake.name}</h3>
        </div>
      </div>

    </div>
  )
}
